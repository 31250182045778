export const TRANSLATIONS_ES_ES = {
    enter_otp_string:"Indique la OTP"+ "<span style='color: red'>*</span>",
    enter_otp:"Indique la OTP",
    generateotp_success:"Se necesita una contraseña de un solo uso (OTP) por motivos de seguridad. La OTP se ha enviado a la dirección de correo electrónico que tenemos ",
    header_text:"Está controlado el acceso a los sistemas informáticos de FCA US LLC. QUEDA PROHIBIDO EL ACCESO O USO NO AUTORIZADOS. Se informa a los usuarios autorizados que la dirección de FCA US LLC puede monitorear este uso para asegurar el cumplimiento de la prohibición. FCA US LLC puede revocar las prerrogativas de acceso, tomar ACCIÓN DISCIPLINARIA y/o iniciar procesos civiles o penales con objeto de hacer cumplir esta política.",
    generateotp_failed:"Error del sistema. Vuelva a intentarlo más tarde.",
    validateotp_failed:"Se ha introducido una contraseña de un solo uso (OTP) incorrecta; inténtelo de nuevo.",
    validateotp_maxattemptreached:"Ha realizado el número ",
    validateotp_expired:"La contraseña de un solo uso (OTP) ha caducado; por favor, utilice el botón Reenviar.",
    resendotp_success:"Se necesita una contraseña de un solo uso (OTP) por motivos de seguridad. La OTP se ha enviado a la dirección de correo electrónico que tenemos ",
    resendotp_maxattemptreached:"Ha realizado el número ",
    sid_email_not_exists:"Su SID no tiene registrada ninguna dirección de correo electrónico; por favor, póngase en contacto con el administrador de su concesionario.",
    tid_email_not_exists:"Su TID no tiene registrada ninguna dirección de correo electrónico; por favor, póngase en contacto con su supervisor.",
    TECHNICAL_ERROR:"Error del sistema. Vuelva a intentarlo más tarde.",
    resendotp_failed:"Error del sistema. Vuelva a intentarlo más tarde.",
    generateotp_success_end:" registrada.<br>Si no recibió una contraseña de un solo uso (OTP), haga clic en Reenviar.",
    resendotp_maxattemptreached_end:" número máximo de intentos de reenvío. Póngase en contacto con el administrador de su concesionario o el Servicio de asistencia para asegurarse de que tiene registrada una dirección de correo electrónico válida. Utilice \"Cancelar\" para volver a la pantalla de inicio de sesión e intentarlo de nuevo.",
    validateotp_maxattemptreached_end:" máximo de intentos de envío. Utilice el botón \"Reenviar\".",
    privacy_end:"Aviso de privacidad",
    otp_header:"Verificación de la OTP",
    footer_line:"FCA US LLC. Todos los derechos reservados. Chrysler, Dodge, Jeep, Ram, Mopar y SRT son marcas registradas de FCA US LLC. <br />ALFA ROMEO y FIAT son marcas registradas de FCA Group Marketing S.p.A., usadas con permiso.",
    required_field:"*-&nbsp;Campos obligatorios",
    submit_btn:"Enviar",
    cancel_btn:"Cancelar",
    resendbtn:"Reenviar",
    generateotp_SMS_success: "One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your mobile number ",
    generateotp_EmailToSMS:"<br> If you would like to start receiving OTP by SMS/Text, select the SMS/Text button and click Resend.<br> If you did not receive an OTP, click Resend.",  resendotp_SMS_success:"One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your Mobile number <br> ",
    generateotp_SMSToEmail:"<br> If you would like to start receiving OTP by Email, select the Email button and click Resend.<br> If you did not receive an OTP, click Resend",
    resendotp_SMS_success:"One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your Mobile number <br> ",
    generateotp_end:" on file."

   };