export const TRANSLATIONS_ES_MX = {
    enter_otp_string:"Ingrese el OTP"+ "<span style='color: red'>*</span>",
    enter_otp:"Introduzca el OTP",
    generateotp_success:"Se necesita el código de acceso de un solo uso (OTP) por motivos de seguridad. El OTP se envió a su dirección de correo electrónico ",
    header_text:"El acceso a los sistemas informáticos del FCA US LLC está controlado. Acceso no autorizado o uso está prohibido. Los usuarios autorizados se informa que el FCA US LLC puede supervisar la gestión de este uso y garantizar su cumplimiento. FCA US LLC pueden terminar los privilegios de acceso, adoptar medidas disciplinarias y/o entablar una acción civil o penal para hacer cumplir esta política.",
    generateotp_failed:"Error del sistema. Intente nuevamente más tarde.",
    validateotp_failed:"Se ingresó el código de acceso de un solo uso (OTP) incorrecto, intente nuevamente.",
    validateotp_maxattemptreached:"Excedió el número máximo ",
    validateotp_expired:"Código de acceso de un solo uso (OTP) caducado, use \"Reenviar\".",
    resendotp_success:"Se necesita el código de acceso de un solo uso (OTP) por motivos de seguridad. El OTP se envió a su dirección de correo electrónico ",
    resendotp_maxattemptreached:"Excedió el número máximo ",
    sid_email_not_exists:"Su SID no cuenta con una dirección de correo electrónico archivada, comuníquese con su administrador de concesionaria.",
    tid_email_not_exists:"Su TID no cuenta con una dirección de correo electrónico archivada, comuníquese con su gerente.",
    TECHNICAL_ERROR:"Error del sistema. Intente nuevamente más tarde.",
    resendotp_failed:"Error del sistema. Intente nuevamente más tarde.",
    privacy_end:"Aviso de privacidad",
    otp_header:"Verificación de OTP",
    footer_line:"FCA US LLC. FIAT es Marca Registrada del Grupo Fiat de Mercadotécnia y Comunicación Corporativa S.p.A. y es usado bajo licencia por el FCA US LLC. ",
    required_field:"*-&nbsp;Campos requeridos",
    submit_btn:"Presentar",
    cancel_btn:"Cancelar",
    resendbtn:"Reenviar",
    generateotp_success_end:"en el archivo.<br>Si no recibió un OTP, haga clic en Reenviar.",
    validateotp_maxattemptreached_end:" de intentos de \"Enviar\". Use \"Reenviar\".",
    resendotp_maxattemptreached_end:" intentos máximos de \"Reenviar\". Comuníquese con su administrador de concesionaria o con el servicio de asistencia para verificar que cuenta con una dirección de correo electrónico válida en el archivo. Use “Cancelar” para volver a la pantalla de inicio de sesión e intente nuevamente.",
    generateotp_SMS_success: "One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your mobile number ",
    generateotp_EmailToSMS:"<br> If you would like to start receiving OTP by SMS/Text, select the SMS/Text button and click Resend.<br> If you did not receive an OTP, click Resend.",  resendotp_SMS_success:"One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your Mobile number <br> ",
    generateotp_SMSToEmail:"<br> If you would like to start receiving OTP by Email, select the Email button and click Resend.<br> If you did not receive an OTP, click Resend",
    resendotp_SMS_success:"One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your Mobile number <br> ",
    generateotp_end:" on file."
    

   };