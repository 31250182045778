export const TRANSLATIONS_JA = {
    enter_otp_string:"OTP を入力" + "<span style='color: red'>*</span>",
    enter_otp:"OTP を入力してください",
    generateotp_success:"セキュリティのため、ワンタイムパスコード (OTP)が必要です。 OTP は、登録されたメールアドレスに ",
    generateotp_success_end:" 送信されました。<br>OTP を受信しなかった場合は、「再送信」をクリックしてください。",
    header_text:"クライスラーグループのコンピュータシステムへのアクセスは管理されています。 不正アクセスまたは使用は禁止されています。 クライスラーグループは、許可されたユーザーの本システムの使用を監視し、コンプライアンスを確認する場合がありますので、ご了承ください。 クライスラーグループは本方針を執行するために、アクセス権を停止したり、懲戒処分および/または民事、あるいは刑事訴訟を行うことがあります。",
    generateotp_failed:"システムエラー。 後で再度お試しください。",
    validateotp_failed:"入力された OTP が間違っています。もう一度入力してください。",
    validateotp_maxattemptreached:"「送信 」の試行回数が上限に達しました。  ",
    validateotp_maxattemptreached_end:" 上限に達しました。 「再送信」を押してください。",
    validateotp_expired:"OTP の有効期限が切れた場合は、「再送信」を押してください。",
    resendotp_success:"セキュリティのため、ワンタイムパスコード（OTP）が必要です。 OTP は、登録されたメールアドレスに ",
    resendotp_maxattemptreached:"「送信 」の試行回数が上限に達しました。 ",
    resendotp_maxattemptreached_end:"「再送信 」の試行回数が上限に達しました。 担当のディーラー管理者またはヘルプ デスクに連絡し、有効な電子メール アドレスが登録されているか確認してください。 「キャンセル」でログイン画面に戻り、再度お試しください。",    
    sid_email_not_exists:"お客様の SID にメールアドレスが登録されていません。担当のディーラー管理者に連絡してください。",
    tid_email_not_exists:"お客様の TID にメールアドレスが登録されていません。あなたのマネージャーに連絡してください。",
    TECHNICAL_ERROR:"システムエラー。 後で再度お試しください。",
    resendotp_failed:"システムエラー。 後で再度お試しください。",
    privacy_end:"プライバシーに関する告知",
    otp_header:"OTP 確認",
    footer_line:"FCA US LLC.All Rights Reserved.Chrysler、Dodge、Jeep、Ram、Mopar、および SRT は FCA US LLC の登録商標です。 <br />ALFA ROMEO および FIAT は FCA Group Marketing S.p.A. の登録商標であり、許可を受けて使用しています。",
    required_field:"*-&nbsp;必須項目",
    submit_btn:"送信",
    cancel_btn:"キャンセル",
    resendbtn:"再送信",
    generateotp_SMS_success: "One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your mobile number ",
    generateotp_EmailToSMS:"<br> If you would like to start receiving OTP by SMS/Text, select the SMS/Text button and click Resend.<br> If you did not receive an OTP, click Resend.",  resendotp_SMS_success:"One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your Mobile number <br> ",
    generateotp_SMSToEmail:"<br> If you would like to start receiving OTP by Email, select the Email button and click Resend.<br> If you did not receive an OTP, click Resend",
    resendotp_SMS_success:"One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your Mobile number <br> ",
    generateotp_end:" on file."
   };