export const TRANSLATIONS_DE = {
    enter_otp_string:"OTP eingeben"+ "<span style='color: red'>*</span>",
    enter_otp:"OTP eingeben",
    generateotp_success:"Aus Sicherheitsgründen ist ein Einmalkennwort (OTP) erforderlich. OTP wurde an Ihre hinterlegte E-Mail-Adresse ",
    generateotp_success_end:" gesendet.<br>Wenn Sie kein OTP erhalten haben, klicken Sie bitte auf, Erneut senden.",
    header_text:"Der Zugriff auf die Computersysteme von FCA US LLC ist beschränkt. BENUTZUNG ODER ZUGRIFF IST FÜR UNBERECHTIGTE VERBOTEN. Berechtigte Benutzer sind hiermit informiert, dass die Leitung der FCA US LLC die bestimmungsgerechte Benutzung möglicherweise überwacht. FCA US LLC behält sich das Recht vor, Zugriffsprivilegien zu entziehen, disziplinare Schritte einzuleiten und/oder diese Bestimmungen auf zivilrechtlichem oder strafrechtlichem Wege zu erzwingen.",
    generateotp_failed:"Systemfehler. Versuchen Sie es später erneut.",
    validateotp_failed:"Falsches Einmalkennwort (OTP) eingegeben, bitte versuchen Sie es erneut.",
    validateotp_maxattemptreached:"Sie haben ",
    validateotp_maxattemptreached_end:" die maximale Anzahl an „Einreichen“-Versuchen erreicht. Bitte verwenden Sie „Erneut senden“.",
    validateotp_expired:"Einmalkennwort (OTP) abgelaufen, bitte verwenden Sie „Erneut senden“.",
    resendotp_success:"Aus Sicherheitsgründen ist ein Einmalkennwort (OTP) erforderlich. OTP wurde an Ihre hinterlegte E-Mail-Adresse ",
    resendotp_maxattemptreached:"Sie haben ",
    resendotp_maxattemptreached_end:" die maximale Anzahl an „Erneut senden“-Versuchen erreicht. Bitte kontaktieren Sie Ihren Händler-Administrator oder den Helpdesk, um zu verifizieren, dass Sie eine gültige E-Mail-Adresse hinterlegt haben. Verwenden Sie „Abbrechen“, um zum Anmeldebildschirm zurückzukehren und es erneut zu versuchen.",
    sid_email_not_exists:"Ihre SID hat keine E-Mail-Adresse hinterlegt, bitte kontaktieren Sie Ihren Händler-Administrator.",
    tid_email_not_exists:"Ihre TID hat keine E-Mail-Adresse hinterlegt, bitte kontaktieren Sie Ihren Manager.",
    TECHNICAL_ERROR:"Systemfehler. Versuchen Sie es später erneut.",
    resendotp_failed:"Systemfehler. Versuchen Sie es später erneut.",
    privacy_end:"Datenschutzerklärung",
    otp_header:"OTP-Verifizierung",
    footer_line:"FCA US LLC. Alle Rechte vorbehalten. Chrysler, Dodge, Jeep, Ram, Mopar und SRT sind eingetragene Marken von FCA US LLC. <br />ALFA ROMEO und FIAT sind eingetragene Marken der FCA Group Marketing S.p.A., die mit Genehmigung verwendet werden. v",
    required_field:"*-Pflichtfelder",
    submit_btn:"Einreichen",
    cancel_btn:"Abbrechen",
    resendbtn:"Erneut senden",
    generateotp_SMS_success: "One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your mobile number ",
    generateotp_EmailToSMS:"<br> If you would like to start receiving OTP by SMS/Text, select the SMS/Text button and click Resend.<br> If you did not receive an OTP, click Resend.",  resendotp_SMS_success:"One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your Mobile number <br> ",
    generateotp_SMSToEmail:"<br> If you would like to start receiving OTP by Email, select the Email button and click Resend.<br> If you did not receive an OTP, click Resend",
    resendotp_SMS_success:"One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your Mobile number <br> ",
    generateotp_end:" on file."
   };