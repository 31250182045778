export const TRANSLATIONS_NL = {
    enter_otp_string:"OTP invoeren"+ "<span style='color: red'>*</span>",
    enter_otp:"Voer OTP in",
    generateotp_success:"Om veiligheidsredenen is een eenmalige toegangscode (OTP) vereist. OTP is naar uw e-mailadres gestuurd ",
    header_text:"Toegang tot FCA US LLC's computersystemen is beschermd.ONBEVOEGDE TOEGANG OF GEBRUIK IS VERBODEN. Hierbij worden bevoegde gebruikers geïnformeerd dat FCA US LLC management gebruikers kan volgen om zo juist gebruik van het systeem af te dwingen. FCA US LLC kan toegang ontzeggen of disciplinaire maatregelen nemen en/of criminele of civiele procedures starten om zo correct gebruik te handhaven.",
    generateotp_failed:"Systeemfout. Probeer het later opnieuw.",
    validateotp_failed:"Onjuiste eenmalige toegangscode (OTP) ingevoerd, probeer het opnieuw.",
    validateotp_maxattemptreached:"U heeft ",
    validateotp_expired:"Eenmalige toegangscode (OTP) verlopen, gebruik \"Opnieuw verzenden\".",
    resendotp_success:"Om veiligheidsredenen is een eenmalige toegangscode (OTP) vereist. OTP is naar uw e-mailadres gestuurd ",
    resendotp_maxattemptreached:"U heeft ",
    sid_email_not_exists:"Uw SID heeft geen e-mailadres in het bestand, neem contact op met uw dealerbeheerder.",
    tid_email_not_exists:"Uw TID heeft geen e-mailadres in het bestand, neem contact op met uw manager.",
    TECHNICAL_ERROR:"Systeemfout. Probeer het later opnieuw.",
    resendotp_failed:"Systeemfout. Probeer het later opnieuw.",
    generateotp_success_end:" in bestand.<br>Klik op Opnieuw verzenden als u geen OTP hebt ontvangen.",
    resendotp_maxattemptreached_end:" maximale aantal pogingen voor 'Opnieuw verzenden' bereikt. Neem contact op met uw dealerbeheerder of helpdesk om te controleren of u een geldig e-mailadres heeft. Gebruik \"Annuleren\" om terug te keren naar het inlogscherm en probeer het opnieuw.",
    validateotp_maxattemptreached_end:" maximale aantal pogingen voor 'Indienen' bereikt. Gebruik \"Opnieuw verzenden\".",
    privacy_end:"Privacy verklaring",
    otp_header:"OTP-verificatie",
    footer_line:"FCA US LLC. Alle rechten voorbehouden. Chrysler, Dodge, Jeep, Ram, Mopar en SRT zijn geregistreerde handelsmerken van FCA US LLC. <br />ALFA ROMEO en FIAT zijn geregistreerde handelsmerken van FCA Group Marketing S.p.A., gebruikt met toestemming.",
    required_field:"*-&nbsp;Verplichte velden",
    submit_btn:"Verzenden",
    cancel_btn:"Annuleren",
    resendbtn:"Opnieuw verzenden",
    generateotp_SMS_success: "One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your mobile number ",
    generateotp_EmailToSMS:"<br> If you would like to start receiving OTP by SMS/Text, select the SMS/Text button and click Resend.<br> If you did not receive an OTP, click Resend.",  resendotp_SMS_success:"One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your Mobile number <br> ",
    generateotp_SMSToEmail:"<br> If you would like to start receiving OTP by Email, select the Email button and click Resend.<br> If you did not receive an OTP, click Resend",
    resendotp_SMS_success:"One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your Mobile number <br> ",
    generateotp_end:" on file."
   };