export const TRANSLATIONS_FR_FR = {
    enter_otp_string:"Saisie de l'OTP"+ "<span style='color: red'>*</span>",
    enter_otp:"Veuillez saisir l'OTP",
    generateotp_success:"Le mot de passe à usage unique (OTP) est requis pour des raisons de sécurité. L'OTP a été envoyé à votre adresse e-mail ",
    header_text:" L'accès aux systémes informatiques de FCA US LLC est contrôlé. L'accès et l'utilisation sans autorisation sont interdits. La direction de FCA US LLC avise les utilisateurs autorisés qu'elle peut surveiller l'utilisation pour s'assurer de la conformité aux règles. FCA US LLC peut mettre fin aux privilèges d'accès, prendre des MESURES DISCIPLINAIRES et entreprendre des poursuites civiles et criminelles en cas de violation des politiques.",
    generateotp_failed:"Erreur système. Veuillez réessayer plus tard.",
    validateotp_failed:"Mot de passe à usage unique (OTP) non correct entré, réessayez.",
    validateotp_maxattemptreached:"Vous avez atteint ",
    validateotp_expired:"Mot de passe à usage unique (OTP) expiré, utilisez « Renvoyer ».",
    resendotp_success:"Le mot de passe à usage unique (OTP) est requis pour des raisons de sécurité. L'OTP a été envoyé à votre adresse e-mail ",
    resendotp_maxattemptreached:"Vous avez atteint ",
    sid_email_not_exists:"Votre SID ne contient pas d'adresse e-mail dans le fichier, contactez l'administrateur de votre concession.",
    tid_email_not_exists:"Votre TID ne contient pas d'adresse e-mail dans le fichier, contactez votre responsable.",
    TECHNICAL_ERROR:"Erreur système. Veuillez réessayer plus tard.",
    resendotp_failed:"Erreur système. Veuillez réessayer plus tard.",
    generateotp_success_end:" dans le fichier.<br>Si vous n'avez pas reçu de mot de passe à usage unique, cliquez sur Renvoyer. ",
    resendotp_maxattemptreached_end:" le nombre maximum de tentatives « Renvoyer ». Veuillez contacter l'administrateur de votre concession ou le Service d'assistance pour vérifier que vous disposez bien d'une adresse e-mail valide enregistrée dans nos fichiers. Utilisez « Annuler » pour revenir à l'écran de connexion et réessayer.",
    validateotp_maxattemptreached_end:" le nombre maximum de tentatives « Envoyer ». Veuillez utiliser « Renvoyer ».",
    privacy_end:"Avis de confidentialité",
    otp_header:"Vérification OTP",
    footer_line:" FCA US LLC. Tous droits réservés. Chrysler, Dodge, Jeep, Ram, Mopar et SRT sont des marques déposées de FCA US LLC. <br />ALFA ROMEO et FIAT sont des marques déposées de FCA Group Marketing S.p.A., utilisées avec permission.",
    required_field:"*-&nbsp;Champ requis",
    submit_btn:"Envoyer",
    cancel_btn:"Annuler",
    resendbtn:"Renvoyer",
    generateotp_SMS_success: "One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your mobile number ",
    generateotp_EmailToSMS:"<br> If you would like to start receiving OTP by SMS/Text, select the SMS/Text button and click Resend.<br> If you did not receive an OTP, click Resend.",  resendotp_SMS_success:"One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your Mobile number <br> ",
    generateotp_SMSToEmail:"<br> If you would like to start receiving OTP by Email, select the Email button and click Resend.<br> If you did not receive an OTP, click Resend",
    resendotp_SMS_success:"One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your Mobile number <br> ",
    generateotp_end:" on file."

   };