export const TRANSLATIONS_ZH = {
    enter_otp_string:"输入 OTP"+"<span style='color: red'>*</span>",
    enter_otp:"请输入 OTP",
    generateotp_success:"出于安全考虑，需要设置一次性密码 (OTP)。 OTP 已发送到您的电子邮件地址 ",
    generateotp_success_end:" 已存档。<br>如果您没有收到 OTP，请点击“重新发送。",
    header_text:"对 FCA US LLC 的电脑系统的访问受到控制。禁止未经授权访问或使用。通告经授权的用户 FCA US LLC 管理层可能对此类使用进行监控并确保符合规定。FCA US LLC 可能终止访问权限、采取纪律处罚措施和/或提起民事或刑事诉讼，以执行本政策。",
    generateotp_failed:"系统错误。 请稍后重试。",
    validateotp_failed:"您输入的一次性密码 (OTP) 不正确，请重试。",
    validateotp_maxattemptreached:"您已达到 ",
    validateotp_expired:"一次性密码 (OTP) 已过期，请使用“重新发送”。",
    resendotp_success:"出于安全考虑，需要设置一次性密码 (OTP)。 OTP 已发送到您的电子邮件地址 ",
    resendotp_maxattemptreached:"您已达到 ",
    sid_email_not_exists:"您的 SID 没有已存档的电子邮件地址，请联系您的经销商管理员。",
    tid_email_not_exists:"您的 TID 没有已存档的电子邮件地址，请联系您的经理。",
    TECHNICAL_ERROR:"系统错误。 请稍后重试。",
    resendotp_failed:"系统错误。 请稍后重试。",
    resendotp_maxattemptreached_end:" 尝试“重新发送”次数上限。 请联系您的经销商管理员或帮助台，以验证您是否拥有已存档的有效电子邮件地址。 使用“取消”返回登录屏幕并重试。",
    validateotp_maxattemptreached_end:" 尝试“提交”次数上限。 请使用“重新发送”。",
    privacy_end:"隐私声明",
    otp_header:"OTP 验证",
    footer_line:"FCA US LLC。保留所有权利。Chrysler、Dodge、Jeep、Ram、Mopar 和 SRT 是 FCA US LLC 的注册商标。 <br />ALFA ROMEO 和 FIAT 是 FCA Group Marketing S.p.A. 的注册商标，经许可才能使用。 ",
    required_field:"*-必填字段",
    submit_btn:"提交",
    cancel_btn:"取消",
    resendbtn:"重新发送",
    generateotp_SMS_success: "One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your mobile number ",
    generateotp_EmailToSMS:"<br> If you would like to start receiving OTP by SMS/Text, select the SMS/Text button and click Resend.<br> If you did not receive an OTP, click Resend.",  resendotp_SMS_success:"One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your Mobile number <br> ",
    generateotp_SMSToEmail:"<br> If you would like to start receiving OTP by Email, select the Email button and click Resend.<br> If you did not receive an OTP, click Resend",
    resendotp_SMS_success:"One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your Mobile number <br> ",
    generateotp_end:" on file."
    
   };