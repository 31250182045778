import Otp from "./components/Otp";
import Layout from "./components/layout/Layout";
import{BrowserRouter as Router, Routes, Route} from "react-router-dom";
//import { useState } from "React";


function App() {
  return (
        <Layout>
          {/* <Routes>
            <Route path='/portal/privateLogin.html' element={<Otp text='Enter OTP *'/>}/>
        </Routes> */}
        {/* <Routes>
            <Route exact path='/portal/Controller/Portal' element={<Otp text='Enter OTP *'/>}/>
        </Routes> */}
        <Routes>
            <Route path='/login/index.html'  element={<Otp/>}/>
        </Routes>
        <Routes>
          <Route path='/login/login.html' element={<Otp/>}/>
        </Routes>
        {/* <Routes>
            <Route exact path='/cali/dcdomain/showdealercodes' element={<Otp/>}/>
        </Routes> */}
        {/* <Routes>
            <Route path='/login'  element={<Otp/>}/>
        </Routes> */}
        
      </Layout>
        
  )
}
    
      
        

export default App;
