import classes from './Layout.module.css'
import logo from '../images/logo.png'
import ChryslerNew from '../images/footer images/ChryslerNew.gif'
import dodge from '../images/footer images/dodge.gif'
import fiat from '../images/footer images/fiat.gif'
import mopar from '../images/footer images/mopar.gif'
import ramm from '../images/footer images/ramm.png'
import FCA from '../images/footer images/stellantis_logo.png'
import AWDigitalVersion from '../images/footer images/AWDigitalVersion.png'
import Jeep from '../images/footer images/Jeep.png'
import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next";
import "../translations/i18n"
import MVP_En from '../images/footer images/MVP_En.jpg'
import banner from '../images/footer images/banner.png'
import MOPAR_3D from '../images/footer images/MOPAR_3D.png'
import vehical from '../images/footer images/vehical.png'





function Layout(props){
    const { t } = useTranslation();
    var userLanguage = window.navigator.userLanguage || window.navigator.language;
 //var userLanguage = 'fr-CA';
//  console.log("userLanguage....."+userLanguage);
 var [lang, country] = userLanguage.split('-');
 
  //console.log('lang and country'+lang+country);
    let privacyNoticePdf;
   if(lang==='en'){
    privacyNoticePdf=<a href="/portal/bzcontent/privacy_statements/privacy_notice/Privacy_Notice_en_US.pdf " target="_blank"><p id='privacy_end'></p></a>
   }
   else if(lang==='de'){
    privacyNoticePdf=<a href="/portal/bzcontent/privacy_statements/privacy_notice/Privacy_Notice_de_DE.pdf " target="_blank"><p id='privacy_end'></p></a>
   }
   else if(lang==='it'){
    privacyNoticePdf=<a href="/portal/bzcontent/privacy_statements/privacy_notice/Privacy_Notice_it_IT.pdf " target="_blank"><p id='privacy_end'></p></a>
   }
   else if(lang==='ja'){
    privacyNoticePdf=<a href="/portal/bzcontent/privacy_statements/privacy_notice/Privacy_Notice_ja_JP.pdf " target="_blank"><p id='privacy_end'></p></a>
   }
   else if(lang==='es' && country==='ES'){
    privacyNoticePdf=<a href="/portal/bzcontent/privacy_statements/privacy_notice/Privacy_Notice_es_ES.pdf " target="_blank"><p id='privacy_end'></p></a>
   }
   else if(lang==='nl'){
    privacyNoticePdf=<a href="/portal/bzcontent/privacy_statements/privacy_notice/Privacy_Notice_nl_NL.pdf " target="_blank"><p id='privacy_end'></p></a>
   }
   else if(lang==='pt'){
    privacyNoticePdf=<a href="/portal/bzcontent/privacy_statements/privacy_notice/Privacy_Notice_pt_BR.pdf " target="_blank"><p id='privacy_end'></p></a>
   }
   else if(lang==='zh'){
    privacyNoticePdf=<a href="/portal/bzcontent/privacy_statements/privacy_notice/Privacy_Notice_zh_CN.pdf " target="_blank"><p id='privacy_end'></p></a>
   }
   else if(lang==='es' && country==='MX'){
    privacyNoticePdf=<a href="/portal/bzcontent/privacy_statements/privacy_notice/Privacy_Notice_es_MX.pdf " target="_blank"><p id='privacy_end'></p></a>
   }else if(lang==='fr' && country==='FR'){
    privacyNoticePdf=<a href="/portal/bzcontent/privacy_statements/privacy_notice/Privacy_Notice_fr_FR.pdf " target="_blank"><p id='privacy_end'></p></a>
   }
   else if(lang==='fr' && country==='CA'){
    privacyNoticePdf=<a href="/portal/bzcontent/privacy_statements/privacy_notice/Privacy_Notice_fr_CA.pdf " target="_blank"><p id='privacy_end'></p></a>
   }
   else{
    privacyNoticePdf=<a href="/portal/bzcontent/privacy_statements/privacy_notice/Privacy_Notice_en_US.pdf " target="_blank"><p id='privacy_end'></p></a>
    }
    
    //const msg = new URLSearchParams(location.search).get('msg');
    useEffect(()=>{
        
            
             document.getElementById("header_text").innerHTML = t("header_text") ;
             document.getElementById("footer_line").innerHTML = t("footer_line") ;
             document.getElementById("privacy_end").innerHTML = t("privacy_end") ;
             document.getElementById("otp_header").innerHTML = t("otp_header") ;
           

    },[])
    
   return (<div>
      
        <div className='headerimage' style={{ borderRadius:"5px",height:50,background:'linear-gradient(to bottom,  #ffffff 0%,#cce3f6 100%)', marginTop:"5px"}}>
        <img className='headerimage' src={logo} alt='' style={{height:30, background:'repeat', marginLeft:"10px"}} />

        </div>
        

        
        <div class="login_content"  style={{textAlign:"center", fontFamily:'sans-serif', fontSize:"12px", lineHeight:1.5,color:'#060000' }}>
            <p style={{color:'1D1D1B'}}id='header_text'>

</p>
</div>
<div>
<div class="login_links" style={{fontSize: "90%",textAlign: "center",padding: "0px 0 0% 0",color: "#0844a4",fontWeight: "bold",fontFamily: "Roboto-Medium"}}>

<p style={{fontWeight:"bold",fontSize:"normal",color:"#0844a4",padding:"7px 0 1% 0",borderRadius:"0px",width:"100%",alignItems:'center', background:'#d4e3fc', display:"flex",justifyContent:"center",fontFamily:"Roboto, sans-serif"}} id='otp_header'></p>
</div>
</div>

<div id="message" className={classes.message}></div>
<div id="errormessage" className={classes.errormessage}></div>
<div id="errormessages" className={classes.errormessages}></div>

{
    
   // <Otp />
}
                <main className={classes.main}>
                    {props.children}    
                </main>
                

                <div className='footerimage' style={{display:"flex",height:"50px", justifyContent:"center", marginTop:'120px',marginRight:'80px'}}>
               <a href="http://www.fcanorthamerica.com/Pages/Home.aspx" target="foo1" ><img className='footerimage'  src={FCA} alt='' style={{marginRight:'0px', marginLeft:'5px'}} /></a>
                <img className='footerimage' src={banner} alt='' style={{ marginRight:'3px', marginLeft:'0px',height:'59px'}} />
                <a href="http://www.chrysler.com/en/" target="foo" onclick="window.open('', 'foo')"><img className='footerimage' src={ChryslerNew} alt='' style={{marginRight:'0px', marginLeft:'5px',paddingTop:'5px'}}/></a>
                <a href="http://www.dodge.com/en/" target="foo" onclick="window.open('', 'foo')"><img className='footerimage' src={dodge} alt='' style={{marginRight:'5px', marginLeft:'5px',height:''}} /></a>
                <a href="http://www.jeep.com/en/" target="foo" onclick="window.open('', 'foo')"><img className='footerimage' src={Jeep} alt='' style={{height:"30px",marginTop:'15px',marginRight:'5px',marginLeft:'5px',height:'22px'}}/></a>
                <a href="http://www.ramtrucks.com" target="foo" onclick="window.open('', 'foo')"><img className='footerimage' src={ramm} alt='' style={{marginRight:'5px', marginLeft:'5px', height:'45px', width:'45px', paddingTop:'5px'}}/></a>
                <a href="https://www.alfaromeousa.com/en/" target="foo" onclick="window.open('', 'foo')"><img className='footerimage' src={AWDigitalVersion} alt='' style={{marginRight:'5px', marginLeft:'5px',height:''}}    /></a>
                <a href="http://www.fiatusa.com/en/" target="foo" onclick="window.open('', 'foo')"><img className='footerimage' src={fiat} alt='' style={{marginRight:'5px', marginLeft:'5px',height:'40px',marginTop:'5px'}} /></a>
                <a href="http://www.mopar.com" target="foo2"><img className='footerimage' src={MOPAR_3D} alt='' style={{marginRight:'3px', marginLeft:'5px',paddingTop:'5px',height:'46px'}}  /></a>
                <img className='footerimage' src={banner} alt='' style={{ marginRight:'5px', marginLeft:'5px',height:'61px'}} />
                <a href="http://www.moparvehicleprotection.com" target="foo" onclick="window.open('', 'foo')"><img className='footerimage' src={vehical}  alt='' style={{marginRight:'0px', marginLeft:'2px',marginTop:'15px',width:'180',height:'23px'}}/></a>


        </div>


                <div class="footer_text" style={{lineHeight:"1.2",textAlign:"center",height:"5px", fontFamily:'sans-serif', marginLeft:'100px', marginRight:'90px', marginTop:'20px', fontSize:'11px'}}>
<p id='footer_line'></p></div>

<div className='footer_end' style={{color:"0844a4",textAlign:"center",height:"4px", fontFamily:'sans-serif', marginLeft:'100px', marginRight:'90px', marginTop:'20px', fontSize:'11px'}}>
{privacyNoticePdf}
</div>



                
            </div>);
}

export default Layout;
