export const TRANSLATIONS_PT = {
    enter_otp_string:"Inserir a OTP"+ "<span style='color: red'>*</span>",
    enter_otp:"Digite a OTP",
    generateotp_success:"É necessário a Senha de uso único (OTP) por razões de segurança. O OTP foi enviado para seu e-mail ",
    header_text:"O acesso aos sistemas de computadores da FCA US LLC é controlado. PROIBIDO O USO OU ACESSO NÃO AUTORIZADO. Os usuários autorizados devem ficar informados de que a gerência da FCA US LLC pode monitorar a utilização dos seus sistemas para verificar a observância com as regras. A FCA US LLC pode revogar privilégios de acesso, aplicar AÇÕES DISCIPLINARES e/ou instaurar processos cíveis ou criminais para garantir o cumprimento desta política.",
    generateotp_failed:"Erro do sistema. Tente novamente mais tarde.",
    validateotp_failed:"Senha de uso único (OTP) incorreta inserida, tente novamente.",
    validateotp_maxattemptreached:"Você atingiu ",
    validateotp_expired:"Senha de uso único (OTP) vencida, use \"Reenviar\".",
    resendotp_success:"É necessário a Senha de uso único (OTP) por razões de segurança. O OTP foi enviado para seu e-mail ",
    resendotp_maxattemptreached:"Você atingiu ",
    sid_email_not_exists:"Seu SID não possui um e-mail cadastrado, entre em contato com o Administrador da Concessionária",
    tid_email_not_exists:"Seu TID não possui um e-mail cadastrado, entre em contato com o Gerente. ",
    TECHNICAL_ERROR:"Erro do sistema. Tente novamente mais tarde.",
    resendotp_failed:"Erro do sistema. Tente novamente mais tarde.",
    generateotp_success_end:" no arquivo.<br>Se você não recebeu um OTP, clique em Reenviar.",
    resendotp_maxattemptreached_end:" o número máximo de tentativas \"Reenviar\". Entre em contato com o Administrador da Concessionária ou Help Desk para verificar se você tem um e-mail válido cadastrado. Use \"Cancelar\" para retornar à tela de login e tente novamente.",
    validateotp_maxattemptreached_end:" o número máximo de tentativas \"Enviar\". Use \"Reenviar\". ",
    privacy_end:"Aviso de Privacidade",
    otp_header:"Verificação da OTP",
    footer_line:"FCA US LLC. Todos os direitos reservados. Chrysler, Dodge, Jeep, Ram, Mopar e SRT são marcas registradas da FCA US LLC. <br />ALFA ROMEO e FIAT são marcas registradas da FCA Group Marketing S.p.A., usadas com permissão.",
    required_field:"*-&nbsp;Campos Obrigatórios",
    submit_btn:"Enviar",
    cancel_btn:"Cancelar",
    resendbtn:"Reenviar",
    generateotp_SMS_success: "One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your mobile number ",
    generateotp_EmailToSMS:"<br> If you would like to start receiving OTP by SMS/Text, select the SMS/Text button and click Resend.<br> If you did not receive an OTP, click Resend.",  resendotp_SMS_success:"One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your Mobile number <br> ",
    generateotp_SMSToEmail:"<br> If you would like to start receiving OTP by Email, select the Email button and click Resend.<br> If you did not receive an OTP, click Resend",
    resendotp_SMS_success:"One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your Mobile number <br> ",
    generateotp_end:" on file."
   };