export const TRANSLATIONS_IT = {
    enter_otp_string:"Inserimento del OTP"+ "<span style='color: red'>*</span>",
    enter_otp:"Inserire il codice OTP",
    generateotp_success:"Per motivi di sicurezza, è richiesta una password monouso (OTP). L'OTP è stata inviata al tuo indirizzo e-mail ",
    header_text:"L'accesso al sistema informatico FCA US LLC è controllato. L'ACCESSO O L'USO NON AUTORIZZATO È PROIBITO. Si informano gli utenti autorizzati che gli amministratori del FCA US LLC monitorano l'uso del sistema e assicurano che esso sia conforme alle norme d'utilizzo. Il FCA US LLC può revocare i privilegi d'accesso, intraprendere AZIONI DISCIPLINARI e/o avviare procedimenti civili e penali per far rispettare questa norma.",
    generateotp_failed:"Errore di sistema. Riprovare più tardi.",
    validateotp_failed:"OTP errata. Riprovare.",
    validateotp_maxattemptreached:"È stato raggiunto il numero massimo di ",
    validateotp_expired:"OTP scaduta. Fare clic su \"Resend\" (Invia di nuovo).",
    resendotp_success:"Per motivi di sicurezza, è richiesta una password monouso (OTP). L'OTP è stata inviata al tuo indirizzo e-mail ",
    resendotp_maxattemptreached:"È stato raggiunto il numero massimo di ",
    sid_email_not_exists:"Il SID indicato non contiene un indirizzo e-mail su file. Si prega di contattare l'amministratore del proprio concessionario.",
    tid_email_not_exists:"Il TID indicato non contiene un indirizzo e-mail su file. Si prega di contattare il proprio superiore.",
    TECHNICAL_ERROR:"Errore di sistema. Riprovare più tardi.",
    resendotp_failed:"Errore di sistema. Riprovare più tardi.",
    generateotp_success_end:" in un file.<br>Se non hai ricevuto un OTP, fai clic su Invia di nuovo.",
    resendotp_maxattemptreached_end:" di tentativi di nuovo invio. Contattare l'amministratore del proprio concessionario o l'help desk per assicurarsi di disporre di un indirizzo e-mail valido nel file. Fare clic su \"Cancel\" (Annulla) nella schermata di accesso e riprovare.",
    validateotp_maxattemptreached_end:" di tentativi di invio. Fare clic su \"Resend\" (Invia di nuovo).",
    privacy_end:"Informativa sulla privacy",
    otp_header:"Verifica OTP",
    footer_line:"FCA US LLC. Tutti i diritti riservati. Chrysler, Dodge, Jeep, Ram, Mopar e SRT sono marchi registrati di FCA US LLC.  <br />ALFA ROMEO e FIAT sono marchi registrati di FCA Group Marketing S.p.A., utilizzati su licenza.",
    required_field:"*-&nbsp;Campi obbligatori",
    submit_btn:"Invia",
    cancel_btn:"Annulla",
    resendbtn:"Invia di nuovo",
    generateotp_SMS_success: "One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your mobile number ",
    generateotp_EmailToSMS:"<br> If you would like to start receiving OTP by SMS/Text, select the SMS/Text button and click Resend.<br> If you did not receive an OTP, click Resend.",  resendotp_SMS_success:"One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your Mobile number <br> ",
    generateotp_SMSToEmail:"<br> If you would like to start receiving OTP by Email, select the Email button and click Resend.<br> If you did not receive an OTP, click Resend",
    resendotp_SMS_success:"One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your Mobile number <br> ",
    generateotp_end:" on file."
   };