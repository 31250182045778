import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
 
import { TRANSLATIONS_ZH } from "./zh/translations";
import { TRANSLATIONS_EN } from "./en/translations";
import {TRANSLATIONS_DE} from "./de/translations";
import {TRANSLATIONS_ES_ES} from "./es_es/translations";
import {TRANSLATIONS_ES_MX} from "./es_mx/translations";
import {TRANSLATIONS_FR_CA} from "./fr_ca/translations";
import {TRANSLATIONS_FR_FR} from "./fr_fr/translations";
import {TRANSLATIONS_IT} from "./it/translations";
import {TRANSLATIONS_JA} from "./ja/translations";
import {TRANSLATIONS_NL} from "./nl/translations";
import {TRANSLATIONS_PT} from "./pt/translations";

i18n
 .use(LanguageDetector)
 .use(initReactI18next)
 .init(
   {
   resources: {
     en: {
       translation: TRANSLATIONS_EN
     },
     zh: {
       translation: TRANSLATIONS_ZH
     },
    de: {
      translation: TRANSLATIONS_DE
    },
    es_es: {
      translation: TRANSLATIONS_ES_ES
    },
    esmx: {
      translation: TRANSLATIONS_ES_MX
    },
    frca: {
      translation: TRANSLATIONS_FR_CA
    },
    frfr: {
      translation: TRANSLATIONS_FR_FR
    },
    it: {
      translation: TRANSLATIONS_IT
    },
    ja: {
      translation: TRANSLATIONS_JA
    },
    nl: {
      translation: TRANSLATIONS_NL
    },
    pt: {
      translation: TRANSLATIONS_PT
    },
    
   }
 });
 var userLanguage = window.navigator.userLanguage || window.navigator.language;
 //var userLanguage = 'fr-CA';
//  console.log("userLanguage....."+userLanguage);
 var [lang, country] = userLanguage.split('-');
 
//  console.log('lang and country'+lang+country);
   if(lang==='en'||lang==='de'||lang==='it'||lang==='ja'||lang==='nl'||lang==='pt'||lang==='zh'){
    //  console.log('first select..'+lang);
         i18n.changeLanguage(lang);
   }
   else if(lang==='es' && country==='ES'){
     i18n.changeLanguage('es_es');
   }
   else if(lang==='es' && country==='MX'){
     i18n.changeLanguage('esmx');
   }else if(lang==='fr' && country==='FR'){
     i18n.changeLanguage('frfr');
   }
   else if(lang==='fr' && country==='CA'){
     console.log('fr canada')
     i18n.changeLanguage('frca');
   }
   else{
     console.log('def lang');
       i18n.changeLanguage('en');
     }
 export default i18n;
 

