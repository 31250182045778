export const TRANSLATIONS_FR_CA = {
    enter_otp_string:"Entrer OTP"+ "<span style='color: red'>*</span>",
    enter_otp:"Veuillez entrer le mot de passe à usage unique",
    generateotp_success:"Un mot de passe à usage unique est requis pour des raisons de sécurité. Le mot de passe à usage unique a été envoyé à l'adresse électronique ", 
    header_text:"L'accès aux systèmes informatiques de FCA US LLC est contrôlé. L'accès et l'utilisation sans autorisation sont interdits. La direction de FCA US LLC avise les utilisateurs autorisés qu'elle peut surveiller l'utilisation pour s'assurer de la conformité aux règles. FCA US LLC peut mettre fin aux privilèges d'accès, prendre des MESURES DISCIPLINAIRES et entreprendre des poursuites civiles et criminelles en cas de violation des politiques.",
    generateotp_failed:"Erreur du système. Veuillez réessayer plus tard.",
    validateotp_failed:"Vous avez saisi un mot de passe à usage unique incorrect, veuillez réessayer.",
    validateotp_maxattemptreached:"Vous avez atteint ",
    validateotp_expired:"Le mot de passe à usage unique a expiré, veuillez utiliser l’option « Renvoyer ».",
    resendotp_success:"Un mot de passe à usage unique est requis pour des raisons de sécurité. Le mot de passe à usage unique a été envoyé à l'adresse électronique ",
    resendotp_maxattemptreached:"Vous avez atteint ",
    sid_email_not_exists:"Votre SID ne contient pas d'adresse électronique, veuillez communiquer avec l'administrateur de votre concession.",
    tid_email_not_exists:"Votre TID ne contient pas d'adresse électronique, veuillez communiquer avec votre gestionnaire.",
    TECHNICAL_ERROR:"Erreur du système. Veuillez réessayer plus tard.",
    resendotp_failed:"Erreur du système. Veuillez réessayer plus tard.",
    generateotp_success_end:" figurant dans votre dossier.<br>Si vous n’avez pas reçu un mot de passe à usage unique, cliquez sur Renvoyer.",
    resendotp_maxattemptreached_end:" le nombre maximal de tentatives de renvoi. Veuillez communiquer avec l'administrateur de votre concession ou le centre d'assistance pour vérifier que votre dossier contient une adresse électronique valide. Utilisez l'option « Annuler » pour retourner à l'écran de connexion et réessayer.",
    validateotp_maxattemptreached_end:" le nombre maximal de tentatives d'envoi. Veuillez utiliser l'option « Renvoyer ».",
    privacy_end:"Déclaration de confidentialité",
    otp_header:"Vérification du mot de passe à usage unique",
    footer_line:"FCA US LLC. Tous droits réservés.  Chrysler, Dodge, Jeep, Ram, Mopar et SRT sont des marques de commerce déposées de FCA US LLC. <br /> ALFA ROMEO et FIAT sont des marques de commerce déposées de Fiat Group Marketing S.p.A., utilisées avec son autorisation.",
    required_field:"*-&nbsp;Champs obligatoires",
    submit_btn:"Soumettre",
    cancel_btn:"Annuler",
    resendbtn:"Renvoyer",
    generateotp_SMS_success: "One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your mobile number ",
    generateotp_EmailToSMS:"<br> If you would like to start receiving OTP by SMS/Text, select the SMS/Text button and click Resend.<br> If you did not receive an OTP, click Resend.",  resendotp_SMS_success:"One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your Mobile number <br> ",
    generateotp_SMSToEmail:"<br> If you would like to start receiving OTP by Email, select the Email button and click Resend.<br> If you did not receive an OTP, click Resend",
    resendotp_SMS_success:"One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your Mobile number <br> ",
    generateotp_end:" on file."

   };