export const TRANSLATIONS_EN = {
    enter_otp_string:"Enter OTP:"+ "&nbsp;<span style='color: black'>*</span>",
    enter_otp:"Please enter OTP",
    generateotp_success: "One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your email address ",
    generateotp_SMS_success: "One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your mobile number ",
    generateotp_success_end:" on file.<br>If you did not receive an OTP, click Resend.",
    generateotp_end:" on file.",
    generateotp_failed:"System Error. Please try again.",
    validateotp_failed:"Incorrect One-Time Passcode (OTP) entered, please try again.",
    validateotp_maxattemptreached: "You have reached ",
    validateotp_maxattemptreached_end:" maximum \"Submit\" attempts. Please use \"Resend\".",
    validateotp_expired: "One-Time Passcode (OTP) expired, please use \"Resend\".",
    resendotp_success:"One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your email address  ",
    generateotp_EmailToSMS:"<br> If you would like to start receiving OTP by SMS/Text, select the SMS/Text button and click Resend.<br> If you did not receive an OTP, click Resend.", 
    resendotp_SMS_success:"One-Time Passcode (OTP) is required for security reasons. OTP has been sent to your Mobile number <br> ",
    generateotp_SMSToEmail:"<br> If you would like to start receiving OTP by Email, select the Email button and click Resend.<br> If you did not receive an OTP, click Resend", 
    resendotp_maxattemptreached:"You have reached ",
    resendotp_maxattemptreached_end:" maximum \"Resend\" attempts. Please contact your Dealership Administrator or Help Desk to verify you have a valid email address on file. Use \"Cancel\" to return to the login screen and try again.",
    header_text:"Access to FCA US LLC's computer systems is controlled. UNAUTHORIZED ACCESS OR USE IS PROHIBITED. Authorized users are hereby informed that FCA US LLC management may monitor this use and ensure compliance. FCA US LLC may terminate access privileges, take DISCIPLINARY ACTION and/or institute civil or criminal proceedings to enforce this policy.",
    sid_email_not_exists:"Your SID does not have an email address on file, please contact your Dealership Administrator.",
    tid_email_not_exists:"Your TID does not have an email address on file, please contact your Manager.",
    TECHNICAL_ERROR:"System Error. Please try again.",
    resendotp_failed:"System Error. Please try again.",
    privacy_end:"Privacy Notice",
    otp_header:"OTP Verification",
    required_field:"* - Required Field",
    footer_line:"&#169;FCA US LLC. All Rights Reserved. Chrysler, Dodge, Jeep, Ram, Mopar and SRT are registered trademarks of FCA US LLC. <br> ALFA ROMEO and FIAT are registered trademarks of FCA Group Marketing S.p.A., used with permission.",
    submit_btn:"Submit",
    cancel_btn:"Cancel",
    resendbtn:"Resend",
    Email: "Em",
    
   };
   