import { useLocation } from 'react-router-dom';
import { useRef, useEffect, useState} from 'react'
import Card from './ui/Card';
import classes from './Otp.module.css';
import { useTranslation } from "react-i18next";
import "./translations/i18n";
import {Box,Typography} from "@material-ui/core";
import $ from 'jquery';

function getCookie(name) {
  return (document.cookie.match('(?:^|;)\\s*'+name.trim()+'\\s*=\\s*([^;]*?)\\s*(?:;|$)')||[])[1];
}

var delete_cookie = function(name) {
  document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};
const otptoggleparam = document.getElementById('helper').getAttribute('data-name');
function Otp(props,event){
  
    const otpInputref = useRef();
   const { t } = useTranslation();
    const location = useLocation();
     const [counter, setCounter] = useState(10);
     const[disable, setDisable]=useState(false);
     const[enableValidate, setEnableValidate ]=useState(false);
     var [otpSelected, setOtpSelected] = useState("");

     const [val, setVal] = useState("");
     var [email, setEmail]=useState("");
     var [phoneno, setPhoneno]=useState("");
     var [showMrktRadio, setShowMrktRadio]=useState("");
     var [otpRadioType, setOtpRadioType]=useState("");


     //const otpMsg = new URLSearchParams(location.search).get('otpMsg');
     //const email = new URLSearchParams(location.search).get('email');
     //const maxcount = new URLSearchParams(location.search).get('maxcount');
    // const email1 = getCookie("emailCk");
     //console.log('otpMsg..'+otpMsg);
     useEffect(() => {
      document.getElementById("required_field").innerHTML = t("required_field") ;
      document.getElementById("otpLabel").innerHTML = t("enter_otp_string") ;
      document.getElementById("submit_btn").innerHTML = t("submit_btn") ;
      document.getElementById("cancel_btn").innerHTML = t("cancel_btn") ;
      document.getElementById("resendbtn").innerHTML = t("resendbtn") ;

        var otpMsg = getCookie("otpMsgCk");
        var email = getCookie("emailCk");
        var phoneno = getCookie("maskedMobileCk");
        var maxcount = getCookie("maxCountCk");
        var showMrktRadio = getCookie("showRadioCk");
        var otpRadioType = getCookie("otpTypeCk");
        setEmail(email);
        setPhoneno(phoneno);
        setShowMrktRadio(showMrktRadio);
       
        setOtpRadioType(otpRadioType);

       if(otpMsg==='generateotp_success')
       {
        if(showMrktRadio=='true'){
        if(otpRadioType=="E")
        {
          if(phoneno=="NM"){
            document.getElementById("message").innerHTML = t("generateotp_success") + email + t("generateotp_success_end");
            setOtpSelected("email");
          }
          else
          {
          document.getElementById("message").innerHTML = t("generateotp_success") + email + t("generateotp_end") + t("generateotp_EmailToSMS");
          setOtpSelected("email");
          }       
        }
      else{
         document.getElementById("message").innerHTML = t("generateotp_SMS_success") + phoneno + t("generateotp_end") + t("generateotp_SMSToEmail");
         setOtpSelected("phoneno");
          }
      }
      else
      {
        document.getElementById("message").innerHTML = t("generateotp_success")+ email+ t("generateotp_success_end");
      }
    }
      if(otpMsg==='generateotp_failed')
       {
        document.getElementById("errormessage").innerHTML = t("generateotp_failed") ;
      }
      if(otpMsg==='resendotp_success')
      {
        
        if(showMrktRadio=='true')
        {
          if(otpRadioType=="E")
          {
            if(phoneno=="NM")
            {
              document.getElementById("message").innerHTML = t("resendotp_success") + email + t("generateotp_success_end");
              setOtpSelected("email");
            }
            else
            {
              document.getElementById("message").innerHTML = t("resendotp_success") + email + t("generateotp_end") + t("generateotp_EmailToSMS");
              setOtpSelected("email");
            }
          }
          else
          {
            document.getElementById("message").innerHTML = t("generateotp_SMS_success") + phoneno + t("generateotp_end") + t("generateotp_SMSToEmail");
            setOtpSelected("phoneno");
          }
        }
      else
      {
        document.getElementById("message").innerHTML = t("resendotp_success")+ email+ t("generateotp_success_end");
      }
    }
      if(otpMsg==='resendotp_maxattemptreached')
       {
        if(otpRadioType=="E")
        {
          document.getElementById("errormessage").innerHTML = t("resendotp_maxattemptreached")+maxcount+ t("resendotp_maxattemptreached_end") ;
          setOtpSelected("email");
        }
        else{
          document.getElementById("errormessage").innerHTML = t("resendotp_maxattemptreached")+maxcount+ t("resendotp_maxattemptreached_end") ;
          setOtpSelected("phoneno");
        }
        
      }
      if(otpMsg==='validateotp_failed')
       {
        if(otpRadioType=="E")
        {
          document.getElementById("errormessage").innerHTML = t("validateotp_failed") ;
          setOtpSelected("email");
        }
        else{
          document.getElementById("errormessage").innerHTML = t("validateotp_failed") ;
          setOtpSelected("phoneno");
        }
       
      }
      if(otpMsg==='validateotp_maxattemptreached')
       {
        if(otpRadioType=="E")
        {
          document.getElementById("errormessage").innerHTML = t("validateotp_maxattemptreached")+maxcount +t("validateotp_maxattemptreached_end") ;
          setOtpSelected("email");
        }
        else{
          document.getElementById("errormessage").innerHTML = t("validateotp_maxattemptreached")+maxcount +t("validateotp_maxattemptreached_end") ;
          setOtpSelected("phoneno");
        }
        
      }
      if(otpMsg==='validateotp_expired')
       {
        document.getElementById("errormessage").innerHTML = t("validateotp_expired") ;
      }
      
     if(otpMsg==='tid_email_not_exists')
     {
      document.getElementById("errormessage").innerHTML = t("tid_email_not_exists") ;
    }
    if(otpMsg==='TECHNICAL_ERROR')
    {
     document.getElementById("errormessage").innerHTML = t("TECHNICAL_ERROR") ;
   }
   if(otpMsg==='generateotp_failed')
   {
    document.getElementById("errormessage").innerHTML = t("generateotp_failed") ;
  }
  if(otpMsg==='resendotp_failed')
  {
   document.getElementById("errormessage").innerHTML = t("resendotp_failed") ;
 }
      
       if(counter===0){
        setDisable(false)
        document.getElementById("resendbtn").style.background="#597dda";

       }
          
        else{
          setDisable(true)
          document.getElementById("resendbtn").style.background="grey";

        }
         const timer =
           counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
          if(otpMsg==='sid_email_not_exists')
          {
            console.log("otpMsg"+otpMsg);
            document.getElementById("submit_btn").disabled = true
            document.getElementById("submit_btn").style.background="grey";
            setDisable(true)
            document.getElementById("resendbtn").style.background="grey";
            document.getElementById("errormessage").innerHTML = t("sid_email_not_exists") ;
            clearInterval(timer);
         }
    
         return () => clearInterval(timer);
       },
     [counter]
     
     );

     
    
    function SubmitOtpHandler(event){
        event.preventDefault();
        console.log('SubmitOtpHandler..');
        const otptoggleparam = document.getElementById("otptoggleparam").value;
        setCounter(0)
        if( otpInputref.current.value.length===0)
        {
            document.getElementById("errormessages").innerHTML = t("enter_otp") ;
            document.getElementById("message").innerHTML = "" ; 
        }
        else{
            setEnableValidate(true)
           // window.location.replace('/cali/dcdomain/showdealercodes?requestType=validateOtp&otpNumber='+otpInputref.current.value)
           //window.location.replace('/portal/Controller/Portal?requestType=validateOtp&otpNumber='+otpInputref.current.value)
       $.ajax({
          type: "POST",
          url: "/cali/dcdomain/showdealercodes",
          data: {
            requestType: 'validateOtp',
            //otpNumber: otpInputref.current.value,
            otpNumber: val,
            otptoggleparam

            //otptoggleparam: $("#otptoggleparam2").val(),
          },
          success: function (result) {
             //alert(result);
             //console.log('result'+result);
             document.open();
             document.write(result);
             document.close();
          },
          //dataType: "json"
        });
      
    }
}

    function ResendOtpHandler(event){
        event.preventDefault();
        setCounter(10)
       
        console.log("counter"+counter);
        
        //window.location.replace('/cali/dcdomain/showdealercodes?requestType=resendOtp')
        //window.location.replace('/portal/Controller/Portal?requestType=resendOtp')
        $.ajax({
          type: "POST",
          url: "/cali/dcdomain/showdealercodes",
          async: false,
          
          data: {
            requestType: 'resendOtp',
            otpSelected,
            
          },
          success: function (result) {
             //alert(result);
             document.open();
             document.write(result);
             document.close();
          },
          //dataType: "json"
        });
    }

    function CancelotpHandler(){
      window.location.replace('/portal/Controller/Logoff')

    }
    const handleChange = event => {
      console.log("event.target.value"+event.target.value);
      setOtpSelected(event.target.value);
      //alert({otpSelected});
      

    };

 
    return (
      
    
      
      
               <Card>
                   
                   <form className={classes.form} onSubmit={SubmitOtpHandler}>
                  
                    <div>
                    { (showMrktRadio=="true") ? (
                      <div>
                        
                         
                          <div className={classes.radioControl}><input  type='radio' id="email" name='otp' value='email' onChange={handleChange} checked={otpSelected === 'email'} />&nbsp; {email} &nbsp;(Email)</div>
                          {(phoneno=="NM")? (<div className={classes.radioControl}><input type='radio' id='NM' name='otp' value='NM' disabled/>&nbsp;No Mobile Number</div>):(
                          <div className={classes.radioControl}><input type='radio' id="phoneno" name='otp' value='phoneno' onChange={handleChange} checked={otpSelected === 'phoneno'}/>&nbsp; {phoneno}&emsp; &emsp; &ensp; (SMS/Text)</div>
                         )}
                       
                       <div className={classes.control}>
                            <label id="otpLabel"/>
                            <input type="text" pattern="[0-9]*" value={val} onChange={(e) =>setVal((v) => (e.target.validity.valid ? e.target.value : v))} maxLength="10" id='otp' ref={otpInputref} /> 
                            <input type="hidden" id="otptoggleparam" name="otptoggleparam" value={otptoggleparam} />
                        </div>
                       
                        </div>
                    ):(
                        <div>
                        <div className={classes.control}>
                         <label id="otpLabel"/>
                            <input type="text" pattern="[0-9]*" value={val} onChange={(e) =>setVal((v) => (e.target.validity.valid ? e.target.value : v))} maxLength="10" id='otp' ref={otpInputref} /> 
                            <input type="hidden" id="otptoggleparam" name="otptoggleparam" value={otptoggleparam} />
                        </div>
                        
                        </div>
                    )}
                       
                        <div className={classes.actions}>
                          <b><label id='required_field'></label></b>
                            <button id="submit_btn" disabled={enableValidate} type="button" onClick={SubmitOtpHandler}>xyz</button>
                            <button id="cancel_btn" type="button" onClick={CancelotpHandler}>xyz</button>
                            <button id='resendbtn' disabled={disable} type="button" style={{cursor:disable?"":"pointer"}} onClick={ResendOtpHandler}>xyz</button>
                        </div>
                        </div>
                    </form>

                    { <Box mt={3} marginLeft="120px">
                      <Typography fontWeight={300} align="center" color="textSecondary" marginLeft="130px" >
                        {" "}
                        <span style={{ color: "white", fontWeight: "bold" }}>
                          {counter}
                        </span>{" "}
                      </Typography>
                    </Box> }

                    
                </Card>
            );
}

export default Otp;
